<template>
    <div class="full-height-layout fill" data-test="supplierImportProductStepCutoff">
        <product-cutoff-times-configurations
            v-if="supplierProductImportedDetails"
            :selected-attributes="selectedAttributesWithOptions"
            :selected-facilities="selectedFacilities"
            :cutoff-time-configurations.sync="cutofftimeConfiguration"
            :v="$v.cutofftimeConfiguration"
            auto-select-first
            class="fill mt-5"
        ></product-cutoff-times-configurations>

        <div class="align-right">
            <mercur-button
                class="btn btn-yellow"
                type="submit"
                :disabled="isSavingProduct"
                @click="save"
                data-test="supplierImportProductProceed"
            >Save and continue</mercur-button>
        </div>
    </div>
</template>

<script>
import SupplierImportedProductMixin from './SupplierImportedProductMixin'
import ProductCutoffTimesConfigurations from '../../../components/products/ProductCutoffTimesConfigurations'
import { cutoffTimeConfigurationsValidation } from '../../../components/utils/HelperValidations.js'

export default {
    name: 'SupplierImportedProductCutoff',
    mixins: [ SupplierImportedProductMixin ],
    components: { ProductCutoffTimesConfigurations },
    data () {
        return {
            cutofftimeConfiguration: [],
        }
    },
    watch: {
        supplierProductImportedDetails () {
            if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.cutofftimeConfiguration) {
                return
            }

            this.setConfigurations()
        },
    },
    computed: {
        selectedFacilities () {
            if (!this.supplierProductImportedDetails) {
                return []
            }

            return this.supplierProductImportedDetails.facilities
        },
        selectedAttributesWithOptions () {
            if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.attributeConfiguration) {
                return []
            }

            const formattedAttributes = []

            for (let key in this.supplierProductImportedDetails.attributeConfiguration) {
                const optionsArray = []
                this.supplierProductImportedDetails.attributeConfiguration[key].forEach((op) => {
                    optionsArray.push({
                        option: op.option,
                        optionName: this.$options.filters.beautify(op.option),
                        selectedValue: op.option,
                    })
                })
                formattedAttributes.push({
                    attributeKey: key,
                    attributeName: this.$options.filters.beautify(key),
                    options: optionsArray,
                })
            }

            return formattedAttributes
        },
        payload () {
            return {
                cutofftimeConfiguration: this.cutofftimeConfiguration,
            }
        },
    },
    validations: {
        cutofftimeConfiguration: { ...cutoffTimeConfigurationsValidation },
    },
    methods: {
        save () {
            this.$emit('save', { ...this.payload,
                onSuccess: () => {
                    this.skipCheckForChanges = true
                    this.$emit('pushRoute', { name: 'SupplierImportedProductArtwork' })
                } })
        },
        checkForChanges () {
            if (!this.skipCheckForChanges && JSON.stringify(this.initialValues.cutofftimeConfiguration) !== JSON.stringify(this.payload.cutofftimeConfiguration)) {
                return true
            }
            return false
        },
        setConfigurations () {
            // PART TO REMOVE FACILITIES FROM CUTOFF CONFIGURATION THAT ARE NOT IN FACILITIES
            const configurations = this.supplierProductImportedDetails.cutofftimeConfiguration || []
            if (this.selectedFacilities && Array.isArray(this.selectedFacilities)) {
                configurations.forEach(configuration => {
                    // FILTER FACILITYIDS
                    if (configuration.facilityIds && Array.isArray(configuration.facilityIds)) {
                        configuration.facilityIds = configuration.facilityIds.filter(facilityId => this.selectedFacilities.map(e => e.locationId).includes(facilityId))
                    }

                    // FILTER COUNTRIES
                    const availableCountries = []
                    this.selectedFacilities.forEach(facility => {
                        if (!facility.serviceLevels) {
                            return
                        }

                        availableCountries.push(...facility.serviceLevels.flatMap(serviceLevel => Object.values(serviceLevel.countriesTo || {})))
                    })
                    configuration.countries = configuration.countries.filter(countryCode => availableCountries.includes(countryCode))

                    // FALLBACK FILTER FOR PRODUCTION DAYS
                    if (typeof configuration.productionDays === 'object' && configuration.productionDays !== null) {
                        configuration.productionDays = Object.values(configuration.productionDays)
                    }
                })
            }
            this.$set(this, 'cutofftimeConfiguration', configurations)
            this.$set(this.initialValues, 'cutofftimeConfiguration', JSON.parse(JSON.stringify(configurations)))
        },
    },
    created () {
        if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.packageConfiguration) {
            return
        }

        this.setConfigurations()
    },
}
</script>
